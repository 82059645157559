import React, { useState } from 'react';
import axios from 'axios';
import { Button, TextField, Box, Typography, Container } from '@mui/material';

function SignUp() {
    const [formData, setFormData] = useState({ username: '', password: '', email: '' });
    const [message, setMessage] = useState('');

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        axios.post('http://localhost:5001/register', formData)
            .then(() => {
                setMessage('User registered successfully!');
            })
            .catch((error) => {
                setMessage(error.response?.data?.error || 'Error occurred during registration');
            });
    };

    return (
        <Container maxWidth="xs">
            <Box sx={{ my: 4 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Sign Up
                </Typography>
                <form onSubmit={handleSubmit}>
                    <TextField
                        label="Username"
                        name="username"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.username}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.email}
                        onChange={handleChange}
                    />
                    <TextField
                        label="Password"
                        name="password"
                        type="password"
                        variant="outlined"
                        fullWidth
                        margin="normal"
                        value={formData.password}
                        onChange={handleChange}
                    />
                    <Button type="submit" variant="contained" color="primary" fullWidth>
                        Register
                    </Button>
                </form>
                {message && <Typography color="error" sx={{ mt: 2 }}>{message}</Typography>}
            </Box>
        </Container>
    );
}

export default SignUp;
