import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography, Container, Grid, Card, CardContent } from '@mui/material';
import { ArrowForward as ArrowForwardIcon } from '@mui/icons-material';

function Home() {
    return (
        <div>
            {/* Navbar Section */}
            <nav className="navbar navbar-expand-lg navbar-light bg-light">
                <div className="container-fluid">
                    <Link className="navbar-brand" to="/">Landscaping SaaS</Link>
                    <div className="d-flex ms-auto">
                        <Link className="btn btn-outline-primary me-2" to="/login">Login</Link>
                        <Link className="btn btn-outline-primary" to="/signup">Sign Up</Link>
                    </div>
                </div>
            </nav>

            {/* Hero Section */}
            <Box
                sx={{
                    backgroundImage: 'url(https://via.placeholder.com/1200x500)', // Replace with your desired image
                    backgroundSize: 'cover',
                    height: '80vh',
                    color: 'white',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    textAlign: 'center',
                    padding: 2
                }}
            >
                <Box>
                    <Typography variant="h2" gutterBottom>
                        Welcome to Landscaping SaaS
                    </Typography>
                    <Typography variant="h5" paragraph>
                        Manage your landscaping projects and clients with ease.
                    </Typography>
                    <Button variant="contained" color="primary" endIcon={<ArrowForwardIcon />} size="large">
                        Get Started
                    </Button>
                </Box>
            </Box>

            {/* Features Section */}
            <Container sx={{ py: 8 }}>
                <Typography variant="h4" textAlign="center" gutterBottom>
                    Features
                </Typography>
                <Grid container spacing={4} justifyContent="center">
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Easy Project Management
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Keep track of all your landscaping projects with detailed task management.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Client Communication
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Communicate seamlessly with clients and keep them updated on project status.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <Card>
                            <CardContent>
                                <Typography variant="h5" gutterBottom>
                                    Automated Scheduling
                                </Typography>
                                <Typography variant="body2" color="textSecondary">
                                    Automatically schedule tasks and reminders for your team and clients.
                                </Typography>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>
            </Container>

            {/* Footer */}
            <Box sx={{ bgcolor: 'background.default', padding: '20px 0' }}>
                <Container maxWidth="lg">
                    <Typography variant="body2" color="textSecondary" textAlign="center">
                        © 2024 Landscaping SaaS. All rights reserved.
                    </Typography>
                </Container>
            </Box>
        </div>
    );
}

export default Home;
