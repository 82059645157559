import axios from 'axios';

const api = axios.create({
    baseURL: 'http://localhost:5001',
});

// Request Interceptor to Add Authorization Header
api.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => Promise.reject(error)
);

// Response Interceptor for Token Expiry
api.interceptors.response.use(
    (response) => response,
    async (error) => {
        const originalRequest = error.config;

        // If token expired and it's not a retry
        if (error.response?.status === 401 && error.response.data.error === 'Token expired' && !originalRequest._retry) {
            originalRequest._retry = true; // Mark as retry to avoid infinite loops
            try {
                // Refresh the token
                const refreshResponse = await axios.post('http://localhost:5001/refresh-token', {}, {
                    headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
                });

                // Save the new token
                const newToken = refreshResponse.data.token;
                localStorage.setItem('token', newToken);

                // Retry the original request with the new token
                originalRequest.headers.Authorization = `Bearer ${newToken}`;
                return api(originalRequest);
            } catch (refreshError) {
                console.error('Token refresh failed:', refreshError.response?.data || refreshError.message);
                // Redirect to login if refresh fails
                window.location.href = '/login';
            }
        }

        return Promise.reject(error);
    }
);

export default api;
