import React from 'react';

function UserSettings() {
    return (
        <div>
            <h1>Settings</h1>
            <p>Here you can update your account settings.</p>
        </div>
    );
}

export default UserSettings;
