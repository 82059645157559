import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

function Expenses() {
    const [expenses, setExpenses] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentExpense, setCurrentExpense] = useState({
        name: '',
        category: '',
        date: '',
        amount: '',
        description: '',
    });
    const categories = ['Fuel', 'Equipment', 'Supplies', 'Labor', 'Other'];

    useEffect(() => {
        fetchExpenses();
    }, []);

    const fetchExpenses = async () => {
        try {
            const response = await axios.get('http://localhost:5001/expenses', {
                headers: { Authorization: `Bearer ${localStorage.getItem('token')}` },
            });
            setExpenses(response.data);
        } catch (error) {
            console.error('Error fetching expenses:', error.response?.data || error.message);
        }
    };

    const handleClickOpen = (expense = null) => {
        if (expense) {
            setCurrentExpense(expense);
        } else {
            setCurrentExpense({
                name: '',
                category: '',
                date: '',
                amount: '',
                description: '',
            });
        }
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentExpense({
            name: '',
            category: '',
            date: '',
            amount: '',
            description: '',
        });
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentExpense({ ...currentExpense, [name]: value });
    };

    const handleSaveExpense = async () => {
        try {
            const token = localStorage.getItem('token');
            if (currentExpense.id) {
                await axios.put(`http://localhost:5001/expenses/${currentExpense.id}`, currentExpense, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                await axios.post('http://localhost:5001/expenses', currentExpense, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchExpenses();
            handleClose();
        } catch (error) {
            console.error('Error saving expense:', error.response?.data || error.message);
        }
    };

    const handleDeleteExpense = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`http://localhost:5001/expenses/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchExpenses();
        } catch (error) {
            console.error('Error deleting expense:', error.response?.data || error.message);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Expenses
            </Typography>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Category</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {expenses.map((expense) => (
                            <TableRow key={expense.id}>
                                <TableCell>{expense.name}</TableCell>
                                <TableCell>{expense.category}</TableCell>
                                <TableCell>{expense.date}</TableCell>
                                <TableCell>${parseFloat(expense.amount).toFixed(2)}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleClickOpen(expense)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDeleteExpense(expense.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentExpense.id ? 'Edit Expense' : 'Add Expense'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentExpense.name}
                        onChange={handleInputChange}
                    />
                    <TextField
                        select
                        label="Category"
                        name="category"
                        fullWidth
                        margin="normal"
                        value={currentExpense.category}
                        onChange={handleInputChange}
                    >
                        {categories.map((category) => (
                            <MenuItem key={category} value={category}>
                                {category}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Date"
                        name="date"
                        type="date"
                        fullWidth
                        margin="normal"
                        value={currentExpense.date}
                        onChange={handleInputChange}
                        InputLabelProps={{
                            shrink: true,
                        }}
                    />
                    <TextField
                        label="Amount"
                        name="amount"
                        type="number"
                        fullWidth
                        margin="normal"
                        value={currentExpense.amount}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Description"
                        name="description"
                        fullWidth
                        margin="normal"
                        value={currentExpense.description}
                        onChange={handleInputChange}
                        multiline
                        rows={3}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveExpense} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Expenses;
