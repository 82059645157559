import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

function Leads() {
    const [leads, setLeads] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [currentLead, setCurrentLead] = useState(null);

    useEffect(() => {
        fetchLeads();
    }, []);

    // Fetch all leads from the backend
    const fetchLeads = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get('http://localhost:5001/leads', {
                headers: { Authorization: `Bearer ${token}` },
            });
            setLeads(response.data);
        } catch (error) {
            console.error('Error fetching leads:', error.response?.data || error.message);
        }
    };

    // Open the modal dialog for adding or editing leads
    const handleClickOpen = (lead = null) => {
        setCurrentLead(lead);
        setOpen(true);
    };

    // Close the modal dialog
    const handleClose = () => {
        setOpen(false);
        setCurrentLead(null);
    };

    // Update the state for form input changes
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentLead({ ...currentLead, [name]: value });
    };

    // Save the lead to the database
    const handleSaveLead = async () => {
        try {
            const token = localStorage.getItem('token');
            if (currentLead?.id) {
                // Update existing lead
                await axios.put(`http://localhost:5001/leads/${currentLead.id}`, currentLead, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            } else {
                // Add new lead
                await axios.post('http://localhost:5001/leads', currentLead, {
                    headers: { Authorization: `Bearer ${token}` },
                });
            }
            fetchLeads(); // Refresh the leads table
            handleClose(); // Close the modal
        } catch (error) {
            console.error('Error saving lead:', error.response?.data || error.message);
        }
    };

    // Delete a lead from the database
    const handleDeleteLead = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`http://localhost:5001/leads/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchLeads(); // Refresh the leads table
        } catch (error) {
            console.error('Error deleting lead:', error.response?.data || error.message);
        }
    };

    // Filter leads based on the search term
    const filteredLeads = leads.filter((lead) =>
        lead.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Leads
            </Typography>
            <TextField
                label="Search Leads"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Phone</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredLeads.map((lead) => (
                            <TableRow key={lead.id}>
                                <TableCell>{lead.name}</TableCell>
                                <TableCell>{lead.phone}</TableCell>
                                <TableCell>{lead.email}</TableCell>
                                <TableCell>{lead.address}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleClickOpen(lead)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDeleteLead(lead.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentLead?.id ? 'Edit Lead' : 'Add Lead'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentLead?.name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Phone"
                        name="phone"
                        fullWidth
                        margin="normal"
                        value={currentLead?.phone || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Email"
                        name="email"
                        fullWidth
                        margin="normal"
                        value={currentLead?.email || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Address"
                        name="address"
                        fullWidth
                        margin="normal"
                        value={currentLead?.address || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveLead} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Leads;
