import React from 'react';

function Reports() {
    return (
        <div>
            <h1>Reports</h1>
            <p>This page will allow users to generate and view reports.</p>
        </div>
    );
}

export default Reports;
