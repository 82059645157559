import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    AppBar,
    Toolbar,
    Typography,
    Container,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    TextField,
    Fab,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    CssBaseline,
    Box,
    Button
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const drawerWidth = 240;

function AdminDashboard() {
    const [users, setUsers] = useState([]);
    const [message, setMessage] = useState('');
    const [openAddDialog, setOpenAddDialog] = useState(false);
    const [openEditDialog, setOpenEditDialog] = useState(false);
    const [newUser, setNewUser] = useState({ username: '', email: '', password: '' });
    const [editUser, setEditUser] = useState({ id: '', username: '', email: '', password: '' });

    useEffect(() => {
        fetchUsers();
    }, []);

    const fetchUsers = async () => {
        try {
            const response = await axios.get('http://localhost:5001/users');
            setUsers(response.data);
        } catch (error) {
            setMessage('Error fetching users');
        }
    };

    const handleDeleteUser = async (userId) => {
        try {
            await axios.delete(`http://localhost:5001/users/${userId}`);
            setMessage('User deleted successfully');
            fetchUsers();
        } catch (error) {
            setMessage('Error deleting user');
        }
    };

    const handleClickOpenAddDialog = () => {
        setOpenAddDialog(true);
    };

    const handleCloseAddDialog = () => {
        setOpenAddDialog(false);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewUser((prev) => ({ ...prev, [name]: value }));
    };

    const handleEditInputChange = (e) => {
        const { name, value } = e.target;
        setEditUser((prev) => ({ ...prev, [name]: value }));
    };

    const handleAddUser = async () => {
        try {
            const { username, email, password } = newUser;
            await axios.post('http://localhost:5001/register', { username, email, password });
            setMessage('User added successfully');
            fetchUsers();
            handleCloseAddDialog();
            setNewUser({ username: '', email: '', password: '' });
        } catch (error) {
            setMessage('Error adding user');
        }
    };

    const handleClickOpenEditDialog = (user) => {
        setEditUser({ id: user.id, username: user.username, email: user.email, password: '' });
        setOpenEditDialog(true);
    };

    const handleCloseEditDialog = () => {
        setOpenEditDialog(false);
    };

    const handleUpdateUser = async () => {
        try {
            const { id, username, email, password } = editUser;
            await axios.put(`http://localhost:5001/users/${id}`, { username, email, password });
            setMessage('User updated successfully');
            fetchUsers();
            handleCloseEditDialog();
        } catch (error) {
            setMessage('Error updating user');
        }
    };

    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            <AppBar position="fixed" sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}>
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        Admin Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Divider />
                <List>
                    <ListItem button>
                        <ListItemText primary="Dashboard" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Manage Users" />
                    </ListItem>
                    <ListItem button>
                        <ListItemText primary="Plans" />
                    </ListItem>
                </List>
            </Drawer>
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                }}
            >
                <Toolbar />
                <Container>
                    <Typography variant="h4" gutterBottom>
                        Admin Dashboard
                    </Typography>
                    {message && <Typography color="error" sx={{ mb: 2 }}>{message}</Typography>}

                    {/* Table to show users */}
                    <TableContainer>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>Username</TableCell>
                                    <TableCell>Email</TableCell>
                                    <TableCell>Role</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {users.map((user) => (
                                    <TableRow key={user.id}>
                                        <TableCell>{user.username}</TableCell>
                                        <TableCell>{user.email}</TableCell>
                                        <TableCell>{user.isAdmin ? 'Admin' : 'User'}</TableCell>
                                        <TableCell>
                                            <IconButton
                                                color="primary"
                                                onClick={() => handleClickOpenEditDialog(user)}
                                            >
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton
                                                color="secondary"
                                                onClick={() => handleDeleteUser(user.id)}
                                            >
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>

                    {/* Floating Action Button for adding new user */}
                    <Fab
                        color="primary"
                        aria-label="add"
                        sx={{ position: 'fixed', bottom: 16, right: 16 }}
                        onClick={handleClickOpenAddDialog}
                    >
                        <AddIcon />
                    </Fab>

                    {/* Dialog to add new user */}
                    <Dialog open={openAddDialog} onClose={handleCloseAddDialog}>
                        <DialogTitle>Add New User</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Username"
                                name="username"
                                fullWidth
                                margin="normal"
                                value={newUser.username}
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                fullWidth
                                margin="normal"
                                value={newUser.email}
                                onChange={handleInputChange}
                            />
                            <TextField
                                label="Password"
                                name="password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={newUser.password}
                                onChange={handleInputChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseAddDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleAddUser} color="primary">
                                Add User
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {/* Dialog to edit user */}
                    <Dialog open={openEditDialog} onClose={handleCloseEditDialog}>
                        <DialogTitle>Edit User</DialogTitle>
                        <DialogContent>
                            <TextField
                                label="Username"
                                name="username"
                                fullWidth
                                margin="normal"
                                value={editUser.username}
                                onChange={handleEditInputChange}
                            />
                            <TextField
                                label="Email"
                                name="email"
                                type="email"
                                fullWidth
                                margin="normal"
                                value={editUser.email}
                                onChange={handleEditInputChange}
                            />
                            <TextField
                                label="Password (Optional)"
                                name="password"
                                type="password"
                                fullWidth
                                margin="normal"
                                value={editUser.password}
                                onChange={handleEditInputChange}
                            />
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleCloseEditDialog} color="primary">
                                Cancel
                            </Button>
                            <Button onClick={handleUpdateUser} color="primary">
                                Update User
                            </Button>
                        </DialogActions>
                    </Dialog>
                </Container>
            </Box>
        </Box>
    );
}

export default AdminDashboard;