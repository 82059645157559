import React from 'react';

function UserProjects() {
    return (
        <div>
            <h1>My Projects</h1>
            <p>Here you can view and manage your projects.</p>
        </div>
    );
}

export default UserProjects;
