import React from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import Home from './Home';
import Login from './Login';
import SignUp from './SignUp';
import AdminDashboard from './AdminDashboard';
import UserDashboard from './UserDashboard';
import UserProjects from './UserProjects';
import UserSettings from './UserSettings';
import UserLayout from './UserLayout';
import Clients from './Clients'; // Import all new pages
import Jobs from './Jobs';
import Leads from './Leads';
import Services from './Services';
import Estimates from './Estimates';
import Invoices from './Invoices';
import Expenses from './Expenses';
import Tickets from './Tickets';
import Calendar from './Calendar';
import Reports from './Reports';
import ClientDetails from './ClientDetails';

function App() {
    return (
        <Router>
            <Routes>
                <Route path="/" element={<Home />} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/admin" element={<AdminDashboard />} />

                {/* Wrap user-specific routes with UserLayout */}
                <Route path="/user" element={<UserLayout />}>
                    <Route path="dashboard" element={<UserDashboard />} />
                    <Route path="projects" element={<UserProjects />} />
                    <Route path="settings" element={<UserSettings />} />
                    <Route path="clients" element={<Clients />} />
                    <Route path="jobs" element={<Jobs />} />
                    <Route path="leads" element={<Leads />} />
                    <Route path="services" element={<Services />} />
                    <Route path="estimates" element={<Estimates />} />
                    <Route path="invoices" element={<Invoices />} />
                    <Route path="expenses" element={<Expenses />} />
                    <Route path="tickets" element={<Tickets />} />
                    <Route path="calendar" element={<Calendar />} />
                    <Route path="reports" element={<Reports />} />
                    <Route path="/user/clients/:id" element={<ClientDetails />} />
                </Route>

                {/* Redirects for outdated or mistyped routes */}
                <Route path="/user-dashboard" element={<Navigate to="/user/dashboard" replace />} />
                <Route path="/user-projects" element={<Navigate to="/user/projects" replace />} />
                <Route path="/user-settings" element={<Navigate to="/user/settings" replace />} />
                <Route path="/user-clients" element={<Navigate to="/user/clients" replace />} />
                <Route path="/user-jobs" element={<Navigate to="/user/jobs" replace />} />
                <Route path="/user-leads" element={<Navigate to="/user/leads" replace />} />
                <Route path="/user-services" element={<Navigate to="/user/services" replace />} />
                <Route path="/user-estimates" element={<Navigate to="/user/estimates" replace />} />
                <Route path="/user-invoices" element={<Navigate to="/user/invoices" replace />} />
                <Route path="/user-expenses" element={<Navigate to="/user/expenses" replace />} />
                <Route path="/user-tickets" element={<Navigate to="/user/tickets" replace />} />
                <Route path="/user-calendar" element={<Navigate to="/user/calendar" replace />} />
                <Route path="/user-reports" element={<Navigate to="/user/reports" replace />} />
                <Route path="/clients/:id" element={<ClientDetails />} />
            </Routes>
        </Router>
    );
}

export default App;
