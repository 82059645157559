import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import axios from 'axios';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
} from '@mui/material';

function ClientDetails() {
    const { id } = useParams(); // Get client ID from the URL
    const [client, setClient] = useState(null);
    const [jobs, setJobs] = useState([]);
    const [invoices, setInvoices] = useState([]);

    useEffect(() => {
        fetchClientDetails();
        fetchClientJobs();
        fetchClientInvoices();
    }, [id]);

    const fetchClientDetails = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`http://localhost:5001/clients/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setClient(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client details');
        }
    };

    const fetchClientJobs = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`http://localhost:5001/clients/${id}/jobs`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setJobs(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client jobs');
        }
    };

    const fetchClientInvoices = async () => {
        try {
            const token = localStorage.getItem('token');
            const response = await axios.get(`http://localhost:5001/clients/${id}/invoices`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setInvoices(response.data);
        } catch (error) {
            handleAuthError(error, 'Error fetching client invoices');
        }
    };

    const handleAuthError = (error, message) => {
        if (error.response?.status === 401 && error.response.data.error === 'Token expired') {
            console.error(`${message}: Token expired. Redirecting to login.`);
            localStorage.removeItem('token'); // Clear the invalid token
            window.location.href = '/login'; // Redirect to login page
        } else {
            console.error(`${message}:`, error.response?.data || error.message);
        }
    };

    if (!client) {
        return <Typography>Loading client details...</Typography>;
    }

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Client Details: {client.name}
            </Typography>
            <Typography variant="body1">Address: {client.address}</Typography>
            <Typography variant="body1">Contact: {client.contact}</Typography>

            <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                Jobs
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Job Name</TableCell>
                            <TableCell>Status</TableCell>
                            <TableCell>Start Date</TableCell>
                            <TableCell>End Date</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {jobs.map((job) => (
                            <TableRow key={job.id}>
                                <TableCell>{job.name}</TableCell>
                                <TableCell>{job.status}</TableCell>
                                <TableCell>{job.start_date}</TableCell>
                                <TableCell>{job.end_date}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>

            <Typography variant="h5" gutterBottom sx={{ marginTop: 4 }}>
                Invoices
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Invoice ID</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Amount</TableCell>
                            <TableCell>Status</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {invoices.map((invoice) => (
                            <TableRow key={invoice.id}>
                                <TableCell>{invoice.id}</TableCell>
                                <TableCell>{invoice.date}</TableCell>
                                <TableCell>{invoice.amount}</TableCell>
                                <TableCell>{invoice.status}</TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Container>
    );
}

export default ClientDetails;
