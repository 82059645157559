import React from 'react';

function Calendar() {
    return (
        <div>
            <h1>Calendar</h1>
            <p>This page will display a calendar for scheduling and managing events.</p>
        </div>
    );
}

export default Calendar;
