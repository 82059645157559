import React from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Drawer,
    List,
    ListItem,
    ListItemText,
    Divider,
    CssBaseline,
    Box,
    Container,
} from '@mui/material';
import { Link } from 'react-router-dom'; // Import Link from React Router

const drawerWidth = 240;

function UserDashboard() {
    return (
        <Box sx={{ display: 'flex' }}>
            <CssBaseline />
            {/* Top AppBar */}
            <AppBar
                position="fixed"
                sx={{
                    width: `calc(100% - ${drawerWidth}px)`,
                    ml: `${drawerWidth}px`,
                }}
            >
                <Toolbar>
                    <Typography variant="h6" noWrap component="div">
                        User Dashboard
                    </Typography>
                </Toolbar>
            </AppBar>

            {/* Left Sidebar */}
            <Drawer
                variant="permanent"
                sx={{
                    width: drawerWidth,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: {
                        width: drawerWidth,
                        boxSizing: 'border-box',
                    },
                }}
            >
                <Toolbar />
                <Divider />
                <List>
                    {/* Sidebar Links */}
                    <ListItem button component={Link} to="/user/dashboard">
                        <ListItemText primary="Home" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/projects">
                        <ListItemText primary="My Projects" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/settings">
                        <ListItemText primary="Settings" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/clients">
                        <ListItemText primary="Clients" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/jobs">
                        <ListItemText primary="Jobs" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/leads">
                        <ListItemText primary="Leads" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/services">
                        <ListItemText primary="Services" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/estimates">
                        <ListItemText primary="Estimates" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/invoices">
                        <ListItemText primary="Invoices" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/expenses">
                        <ListItemText primary="Expenses" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/tickets">
                        <ListItemText primary="Tickets" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/calendar">
                        <ListItemText primary="Calendar" />
                    </ListItem>
                    <ListItem button component={Link} to="/user/reports">
                        <ListItemText primary="Reports" />
                    </ListItem>
                </List>
            </Drawer>

            {/* Main Content Area */}
            <Box
                component="main"
                sx={{
                    flexGrow: 1,
                    bgcolor: 'background.default',
                    p: 3,
                }}
            >
                <Toolbar />
                <Container>
                    <Typography variant="h4" gutterBottom>
                        Welcome to Your Dashboard
                    </Typography>
                    <Typography>
                        Use the sidebar to navigate through features.
                    </Typography>
                </Container>
            </Box>
        </Box>
    );
}

export default UserDashboard;
