import React, { useState, useEffect, useCallback } from 'react';
import axios from 'axios';
import {
    Container,
    Typography,
    TextField,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';

function Clients() {
    const [clients, setClients] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [open, setOpen] = useState(false);
    const [currentClient, setCurrentClient] = useState(null);
    const [error, setError] = useState('');
    const navigate = useNavigate();

    const fetchClients = useCallback(async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            const response = await axios.get('http://localhost:5001/clients', {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            setClients(response.data);
        } catch (err) {
            if (err.response?.status === 403 || err.response?.status === 401) {
                console.error('Access denied. Attempting token refresh.');
                await refreshAuthToken();
            } else {
                console.error('Error fetching clients:', err.message);
                setError(err.message || 'Failed to fetch clients.');
            }
        }
    }, []); // Stable reference for `fetchClients`

    const refreshAuthToken = useCallback(async () => {
        try {
            const refreshToken = localStorage.getItem('refreshToken');
            if (!refreshToken) throw new Error('Refresh token missing. Please log in again.');

            const response = await axios.post('http://localhost:5001/refresh-token', {}, {
                headers: { Authorization: `Bearer ${refreshToken}` },
            });

            const { accessToken } = response.data;
            localStorage.setItem('accessToken', accessToken);
            console.log('Access token refreshed successfully.');
            await fetchClients(); // Retry fetching clients after refresh
        } catch (err) {
            console.error('Error refreshing token:', err.message);
            localStorage.removeItem('accessToken');
            localStorage.removeItem('refreshToken');
            navigate('/login'); // Redirect to login if refresh fails
        }
    }, [fetchClients, navigate]);

    useEffect(() => {
        fetchClients();

        const interval = setInterval(() => {
            refreshAuthToken();
        }, 50 * 60 * 1000); // Refresh token every 50 minutes
        return () => clearInterval(interval); // Cleanup interval on unmount
    }, [fetchClients, refreshAuthToken]);

    const handleClickOpen = (client = null) => {
        setCurrentClient(client);
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setCurrentClient(null);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentClient({ ...currentClient, [name]: value });
    };

    const handleSaveClient = async () => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            if (currentClient?.id) {
                await axios.put(`http://localhost:5001/clients/${currentClient.id}`, currentClient, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
            } else {
                await axios.post('http://localhost:5001/clients', currentClient, {
                    headers: { Authorization: `Bearer ${accessToken}` },
                });
            }
            fetchClients();
            handleClose();
        } catch (err) {
            console.error('Error saving client:', err.response?.data || err.message);
            setError(err.message || 'Failed to save client.');
        }
    };

    const handleDeleteClient = async (id) => {
        try {
            const accessToken = localStorage.getItem('accessToken');
            if (!accessToken) throw new Error('Access token missing. Please log in again.');

            await axios.delete(`http://localhost:5001/clients/${id}`, {
                headers: { Authorization: `Bearer ${accessToken}` },
            });
            fetchClients();
        } catch (err) {
            console.error('Error deleting client:', err.response?.data || err.message);
            setError(err.message || 'Failed to delete client.');
        }
    };

    const handleRowClick = (client) => {
        navigate(`/clients/${client.id}`); // Navigate to the client details page
    };

    const filteredClients = clients.filter((client) =>
        client.name.toLowerCase().includes(searchTerm.toLowerCase())
    );

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Clients
            </Typography>
            {error && <Typography color="error" sx={{ mb: 2 }}>{error}</Typography>}
            <TextField
                label="Search Clients"
                variant="outlined"
                fullWidth
                margin="normal"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
            />
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Name</TableCell>
                            <TableCell>Address</TableCell>
                            <TableCell>Contact</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {filteredClients.map((client) => (
                            <TableRow
                                key={client.id}
                                onClick={() => handleRowClick(client)}
                                style={{ cursor: 'pointer' }}
                            >
                                <TableCell>{client.name}</TableCell>
                                <TableCell>{client.address}</TableCell>
                                <TableCell>{client.contact}</TableCell>
                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            handleClickOpen(client);
                                        }}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={(e) => {
                                            e.stopPropagation(); // Prevent row click event
                                            handleDeleteClient(client.id);
                                        }}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentClient?.id ? 'Edit Client' : 'Add Client'}</DialogTitle>
                <DialogContent>
                    <TextField
                        label="Name"
                        name="name"
                        fullWidth
                        margin="normal"
                        value={currentClient?.name || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Address"
                        name="address"
                        fullWidth
                        margin="normal"
                        value={currentClient?.address || ''}
                        onChange={handleInputChange}
                    />
                    <TextField
                        label="Contact"
                        name="contact"
                        fullWidth
                        margin="normal"
                        value={currentClient?.contact || ''}
                        onChange={handleInputChange}
                    />
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveClient} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Clients;
