import React, { useState, useEffect } from 'react';
import axios from 'axios';
import api from './api';
import {
    Container,
    Typography,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    IconButton,
    Fab,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    TextField,
    MenuItem,
} from '@mui/material';
import { Add, Edit, Delete } from '@mui/icons-material';

function Estimates() {
    const [estimates, setEstimates] = useState([]);
    const [clients, setClients] = useState([]);
    const [open, setOpen] = useState(false);
    const [currentEstimate, setCurrentEstimate] = useState({ total_amount: 0 }); // Ensure total_amount is a number
    const [items, setItems] = useState([]);

    useEffect(() => {
        fetchEstimates();
        fetchClients();
    }, []);

    const fetchEstimates = async () => {
        try {
            const response = await api.get('/estimates'); // No need to manually handle token
            setEstimates(response.data);
        } catch (error) {
            console.error('Error fetching estimates:', error.response?.data || error.message);
        }
    };    
    const fetchClients = async () => {
        try {
            const response = await api.get('/clients'); // No need to manually handle token
            setClients(response.data);
        } catch (error) {
            console.error('Error fetching clients:', error.response?.data || error.message);
        }
    };    

    const handleClickOpen = (estimate = null) => {
        if (estimate) {
            // Ensure the date is in YYYY-MM-DD format for the input field
            const formattedDate = estimate.date ? new Date(estimate.date).toISOString().split('T')[0] : '';
            setCurrentEstimate({ ...estimate, date: formattedDate });
            setItems(estimate.items || []);
        } else {
            setCurrentEstimate({ client_id: '', date: '', total_amount: 0 }); // Reset for new estimate
            setItems([]);
        }
        setOpen(true);
    };    

    const handleClose = () => {
        setOpen(false);
        setCurrentEstimate({ total_amount: 0 }); // Reset total_amount when closing
        setItems([]);
    };

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setCurrentEstimate({ ...currentEstimate, [name]: value });
    };

    const handleItemChange = (index, field, value) => {
        const updatedItems = [...items];
        updatedItems[index][field] = value;
        setItems(updatedItems);
    };

    const addItem = () => {
        setItems([...items, { description: '', quantity: 1, unit_price: 0 }]);
    };

    const removeItem = (index) => {
        const updatedItems = [...items];
        updatedItems.splice(index, 1);
        setItems(updatedItems);
    };

    useEffect(() => {
        const calculateTotalAmount = () => {
            const total = items.reduce((sum, item) => sum + item.quantity * item.unit_price, 0);
            setCurrentEstimate((prev) => ({ ...prev, total_amount: total })); // Functional update
        };

        calculateTotalAmount();
    }, [items]); // Only depend on 'items', not 'currentEstimate'

    const handleSaveEstimate = async () => {
        try {
            //const token = localStorage.getItem('token');
    
            // Format the date to 'YYYY-MM-DD'
            const formattedEstimate = {
                ...currentEstimate,
                date: new Date(currentEstimate.date).toISOString().split('T')[0], // Extract only the date part
                items,
            };
    
            if (currentEstimate?.id) {
                // Update existing estimate
                await api.put(`/estimates/${currentEstimate.id}`, formattedEstimate);
            } else {
                // Create new estimate
                await api.post('/estimates', formattedEstimate);
            }
    
            fetchEstimates(); // Refresh estimates
            handleClose(); // Close the dialog
        } catch (error) {
            console.error('Error saving estimate:', error.response?.data || error.message);
        }
    };
    
    const handleDeleteEstimate = async (id) => {
        try {
            const token = localStorage.getItem('token');
            await axios.delete(`http://localhost:5001/estimates/${id}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            fetchEstimates();
        } catch (error) {
            console.error('Error deleting estimate:', error.response?.data || error.message);
        }
    };

    return (
        <Container>
            <Typography variant="h4" gutterBottom>
                Estimates
            </Typography>
            <TableContainer component={Paper}>
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Client Name</TableCell>
                            <TableCell>Date</TableCell>
                            <TableCell>Total Amount</TableCell>
                            <TableCell>Actions</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {estimates.map((estimate) => (
                            <TableRow key={estimate.id}>
                                <TableCell>{estimate.client_name}</TableCell>
                                <TableCell>
                                {new Date(estimate.date).toLocaleDateString('en-US', {
                                month: '2-digit',
                                day: '2-digit',
                                year: 'numeric',
                                })}
                                </TableCell>
                                <TableCell>
                                    {estimate.total_amount !== null && !isNaN(Number(estimate.total_amount))
                                    ? `$${Number(estimate.total_amount).toFixed(2)}`
                                    : 'Invalid Amount'}
                                </TableCell>

                                <TableCell>
                                    <IconButton
                                        color="primary"
                                        onClick={() => handleClickOpen(estimate)}
                                    >
                                        <Edit />
                                    </IconButton>
                                    <IconButton
                                        color="secondary"
                                        onClick={() => handleDeleteEstimate(estimate.id)}
                                    >
                                        <Delete />
                                    </IconButton>
                                </TableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
            <Fab
                color="primary"
                aria-label="add"
                sx={{ position: 'fixed', bottom: 16, right: 16 }}
                onClick={() => handleClickOpen()}
            >
                <Add />
            </Fab>
            <Dialog open={open} onClose={handleClose}>
                <DialogTitle>{currentEstimate?.id ? 'Edit Estimate' : 'Add Estimate'}</DialogTitle>
                <DialogContent>
                    <TextField
                        select
                        label="Client"
                        name="client_id"
                        fullWidth
                        margin="normal"
                        value={currentEstimate?.client_id || ''}
                        onChange={handleInputChange}
                    >
                        {clients.map((client) => (
                            <MenuItem key={client.id} value={client.id}>
                                {client.name}
                            </MenuItem>
                        ))}
                    </TextField>
                    <TextField
                        label="Date"
                        type="date"
                        name="date"
                        fullWidth
                        margin="normal"
                        InputLabelProps={{ shrink: true }}
                        value={currentEstimate?.date || ''}
                        onChange={handleInputChange}
                    />
                    <Typography variant="h6" gutterBottom>
                        Items
                    </Typography>
                    {items.map((item, index) => (
                        <div key={index}>
                            <TextField
                                label="Description"
                                fullWidth
                                margin="normal"
                                value={item.description}
                                onChange={(e) => handleItemChange(index, 'description', e.target.value)}
                            />
                            <TextField
                                label="Quantity"
                                type="number"
                                fullWidth
                                margin="normal"
                                value={item.quantity}
                                onChange={(e) => handleItemChange(index, 'quantity', parseInt(e.target.value) || 1)}
                            />
                            <TextField
                                label="Unit Price"
                                type="number"
                                fullWidth
                                margin="normal"
                                value={item.unit_price}
                                onChange={(e) =>
                                    handleItemChange(index, 'unit_price', parseFloat(e.target.value) || 0)
                                }
                            />
                            <Button onClick={() => removeItem(index)} color="secondary">
                                Remove Item
                            </Button>
                        </div>
                    ))}
                    <Button onClick={addItem} color="primary">
                        Add Item
                    </Button>
                    <Typography variant="h6" gutterBottom>
                        Total Amount: ${isNaN(currentEstimate.total_amount) ? 0 : Number(currentEstimate.total_amount).toFixed(2)}
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="secondary">
                        Cancel
                    </Button>
                    <Button onClick={handleSaveEstimate} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
}

export default Estimates;
